<template>
  <div class="chat-container" ref="searchBar">
    <header class="header">
      <div>
        <van-icon
          name="arrow-left"
          size="24"
          class="back-icon"
          @click="goHome"
        />
      </div>
      <div v-show="nameChangeShow">
        <input
          type="text"
          class="input-name"
          v-model="newName"
          @blur="saveName"
        />
      </div>
      <div class="header-text" v-show="!nameChangeShow">
        {{ title }}
        <img
          style="width: 0.24rem; margin-left: 0.2rem"
          src="@/assets/images/changeName.png"
          @click="changeName"
        />
      </div>
      <div v-if="deletes" class="remove-icon">
        <van-icon @click="remove" name="ellipsis" size="27" />
        <div class="remove-text" v-if="removeShow" @click="removeChat">
          删除对话
          <div class="remove-text-top"></div>
        </div>
      </div>
      <div v-else></div>
    </header>

    <!-- <div class="chat-lists" ref="scrollDiv">
            <div v-for="(v,i) in chatList" :key="i">
                    <div v-if="v.m === '1'" class="chat-my">
                        <div>{{v.time}}</div>
                        <div>{{v.text}}</div>
                        <div>
                            <img src="@/assets/images/cuo.png" alt="">
                        </div>
                    </div>
                    <div v-else class="chat-ai">
                         <div>{{v.time}}</div>
                        <div>{{v.text}}</div>
                        <div>
                            <img src="@/assets/images/cuo.png" alt="">
                        </div>
                    </div>
            </div>
        </div> -->
    <!-- @click="removeNO" -->
    <main class="chat-content" ref="scrollDiv">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        loosing-text="加载中..."
        pulling-text="下拉加载历史聊天..."
        :disabled="disabled"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="getTalkList"
          :immediate-check="false"
        >
          <section class="chat-list" ref="chatWindow">
            <ul
              v-for="(item, index) in messageList"
              :key="index"
              :class="{
                'customer-server-msg': !messageIsSelf(item),
                'customer-msg': messageIsSelf(item),
                'customer-sendTime': item.sendTime,
              }"
              :id="`item${index}`"
            >
              <li v-if="item.sendTime" class="msg-info-time">
                <p>{{ item.sendTime | time }}</p>
              </li>

              <!-- <li
                class="header-img"
                style="border-radius: 0; background: transparent"
                v-if="!messageIsSelf(item)"
              >
               
                <img
                  style="width: 0.72rem"
                  src="@/assets/images/ai.png"
                  alt=""
                />
              </li> -->
              <li class="msg-info">
                <p
                  class="msg-info-con"
                  style="padding: 0"
                  v-if="item.is"
                  :class="{ 'customer-bgc': messageIsSelf(item) }"
                >
                  <span style="padding: 0.3rem">
                    <img
                      style="margin-top: 0.1rem; width: 0.5rem"
                      src="@/assets/images/lod3.gif"
                  /></span>
                  <!-- <span class="msg-tip"></span> -->
                </p>

                <div
                  class="msg-info-con"
                  @click="log($event, index)"
                  v-else
                  :class="{ 'customer-bgc': messageIsSelf(item) }"
                >
                  <!-- <span v-html="item.content"></span> -->
                  <transition name="van-fade">
                    <div v-show="showPopover && currentIndex == index">
                      <!-- v-if="showPopover&&currentIndex==index" -->
                      <span class="copy-text" @click.stop="copy">复制</span>
                    </div>
                  </transition>

                  <MarkdownPreview
                    :initialValue="item.content"
                    :markedOptions="{
                      width: 100,
                    }"
                    class="parser"
                  />
                  <!-- <span class="msg-tip"></span> -->
                </div>
              </li>
              <!-- <li class="header-img" v-if="messageIsSelf(item)">
                <img src="@/assets/images/qq.png" alt />
              </li> -->
            </ul>
          </section>
        </van-list>
      </van-pull-refresh>
    </main>

    <div class="bottom">
      <!-- <img src="@/assets/images/voice.png" alt="" class="record"> -->
      <div class="helper" v-if="false">
        <div class="helper-item">
          <img src="@/assets/images/icon_cost.png" alt="" />
          <van-loading type="spinner" v-if="isTrue" size=".28rem" />
          <span v-else>{{ tokens }}</span>
        </div>
      </div>
      <div class="send-area">
        <van-field
          v-model="text"
          class="text"
          placeholder="和Ta说点什么~"
          @focus="enBottom"
          rows="1"
          type="textarea"
          :autosize="{ maxHeight: 150 }"
        />
        <!-- <input
          v-model="text"
          class="text"
          placeholder="和Ta说点什么~"
          @focus="enBottom"
          @blur="enBottomNo"
          :adjust-position="false"
        /> -->
        <button v-show="!btnText" class="bottom-send" @click="en()">
          发送
        </button>
        <button v-show="btnText" class="bottom-send" @click="saveName()">
          保存
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils/youngbook";
import { MarkdownPreview } from "vue-meditor";
import { fetchEventSource } from "@microsoft/fetch-event-source";

//   window.addEventListener('resize', function () {
//        this.$toast('调用键盘')
//     var height = window.innerHeight;
//     var inputHeight = document.getElementById('input').offsetHeight;
//     var bottomHeight = document.getElementById('bottom').offsetHeight;
//     if (height < inputHeight + bottomHeight) {
//     document.getElementById("bottom").style.position = 'absolute';
//     } else {
//         document.getElementById( 'bottom').style.position = 'fixed';
//     }
//     })

export default {
  data() {
    return {
      title: "",
      text: "",

      loading: true,
      successText: "刷新成功",
      loosingText: "",

      //是否修改名字
      nameChangeShow: false,
      newName: "",

      //删除展示
      removeShow: false,

      messageList: [
        // {m:'1',sendTime:'2022-03-02',content:'1'},
      ],
      id: undefined,
      deletes: false,
      chatId: "",
      btnText: false,

      //判断是否返回
      isTrue: false,
      queryKeys: {
        userId: localStorage.getItem("id"),
        pageNum: 1,
        pageSize: 5,
      },
      refreshing: false,
      loading: false,
      finished: false,
      // 是否禁用下拉刷新
      disabled: false,
      // 消耗的token
      tokens: "",
      showPopover: false,
      currentIndex: -1,
      currentCopyText: "",
      timerId: undefined,
    };
  },
  components: { MarkdownPreview },
  filters: {
    time(time) {
      if (!time) {
        return "";
      }
      return parseTime(time);
    },
  },
  mounted() {
    if (!this.$route.query.is) {
      this.deletes = true;
    }
    if (this.$route.query.time) {
      this.title = this.$route.query.time;
      this.deletes = false;
      this.addChat();
      return;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.init();
  },
  methods: {
    log(e, index) {
      this.showPopover = true;
      this.currentIndex = index;
      if (
        e.target.offsetParent &&
        e.target.offsetParent.className == "code-block"
      )
        return;
      this.currentCopyText =
        e.target.offsetParent && e.target.offsetParent.innerText;
      this.timerId && clearTimeout(this.timerId);
      this.timerId = setTimeout(() => (this.showPopover = false), 3000);
    },
    copy() {
      this.showPopover = false;

      const input = document.createElement("input");
      input.value = this.currentCopyText;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      input.remove();
      this.$toast("复制成功!");
    },
    async getTopicInfo() {
      const res = await this.$api.chat.getTopicInfo({ id: this.id });
      this.title = res.data.titleName;
    },
    goHome() {
      this.$router.push("/index");
    },
    init() {
      this.getTopicInfo();
      this.getTalkList();
    },
    //新增话题
    addChat() {
      this.$api.chat
        .addAsk({
          userId: localStorage.id,
          title: this.title,
        })
        .then((res) => {
          if (res.code == 200) {
            this.topicId = res.data.id;
            this.id = res.data.id;
            this.init();
          }
        });
    },
    // 刷新
    onRefresh() {
      // this.queryKeys.pageSize = 2;
      this.finished = false;
      this.loading = true;
      this.getTalkList();
    },
    //打开话题
    getTalkList() {
      this.loading = true;
      this.$api.chat
        .getAskDetail({ ...this.queryKeys, topicId: this.id })
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            this.finished = true;
            this.refreshing = false;

            this.messageList = [...res.data.rows, ...this.messageList];
            for (let i = this.messageList.length - 1; i >= 0; i--) {
              this.messageList[i].sendTime = this.messageList[i].time;
              this.messageList[i].content = this.messageList[i].msg;
              if (this.messageList[i].sender == 0) {
                this.messageList[i].sendByName = "Open";
              } else {
                this.messageList[i].sendByName = "1";
              }

              if (
                this.timeToTimestamp(this.messageList[i].sendTime) -
                  this.timeToTimestamp(
                    this.messageList[i - 1]
                      ? this.messageList[i - 1].sendTime
                      : this.messageList[i].sendTime
                  ) <
                600000
              ) {
                //    console.log(i);
                this.messageList[i].sendTime = "";
              }
            }
            for (let i = this.messageList.length - 1; i > 0; i--) {
              if (
                this.timeToTimestamp(this.messageList[i].sendTime) -
                  this.timeToTimestamp(this.messageList[i - 1].sendTime) <
                600000
              ) {
                this.messageList[i].sendTime = "";
              }
            }

            if (this.$route.query.is) {
              this.deletes = false;
              if (this.$route.query.text) {
                console.log(this.$route.query.text);
                let text = this.$route.query.text;
                this.enIs(text);
              }
            }
            this.$nextTick(() => {
              document.querySelector("#item2") &&
                document.querySelector("#item2").scrollIntoView();
            });

            if (res.data.rows.length < this.queryKeys.pageSize) {
              this.disabled = true;
              return;
            }

            this.queryKeys.pageNum++;
          }
        });
    },
    //修改名字
    changeName() {
      (this.nameChangeShow = true), (this.newName = "");
      this.btnText = true;
      this.removeNO();
      this.$nextTick(() => {
        document.querySelectorAll(".input-name")[0].focus();
      });
    },
    //展示删除
    remove() {
      this.removeShow = true;
    },
    removeChat() {
      this.$api.chat.removeChat(this.id).then((res) => {
        if (res.code === 200) {
          this.$toast("删除成功");
          this.$router.push("index");
        }
      });
    },
    removeNO() {
      if (this.removeShow) {
        this.removeShow = false;
      }
    },
    messageIsSelf(message) {
      //TODO 报null错误？
      // console.log(message);
      if (message && message.sendByName) {
        return message.sendByName.substr(0, 4) !== "Open";
      }
    },

    en() {
      if (!this.text) return;
      // if(this.messageList.length && this.messageList[this.messageList.length-1].is){

      if (this.isTrue) {
        this.$toast("AI正在努力为您查询,请稍等一下哦~~~");
        return;
      }
      this.isTrue = true;

      this.messageList.push({
        sendByName: "1",
        sendTime: parseTime(Date.now()),
        content: this.text,
      });
      this.messageList.push({
        sendByName: "Open",
        sendTime: parseTime(Date.now()),
        is: true,
      });
      this.$nextTick(() => {
        this.scrollToBottom();
      });

      // this.messageList.push( {m:'2',sendTime:'1566199627349',is:false,content:'据西湖大学公众号消息，该校学院何睿华课题组连同研究合作者一起，发现了世界首例具有本征相干性的光阴极量子材料，其性能远超传统的光阴极材料，且无法为现有理论所解释，为光阴极研发、应用与基础理论发展打开了新的天地。3月8日，相关论文“Anomalous intense coherent secondary photoemission from a perovskite oxide”，已提前线上发表于Nature期刊。西湖大学博士研究生洪彩云、邹文俊和冉鹏旭为共同第一作者，西湖大学理学院长聘副教授何睿华为通讯作者。全部实验和理论工作都在西湖大学完成。西湖大学介绍称，1887年，德国物理学家赫兹在实验中意外发现，紫外线照射到金属表面电极上会产生火花。'})
      // this.messageList.push( {m:'2',sendTime:'1566199627349',is:false,content:' 12312321'})

      // console.log(Date.now (),this.timeToTimestamp('2022-03-10 13:20:50'));
      for (let i = this.messageList.length - 1; i > 0; i--) {
        //    console.log('for');
        //    console.log(this.messageList[i],this.timeToTimestamp(this.messageList[i].sendTime));
        if (
          this.timeToTimestamp(this.messageList[i].sendTime) -
            this.timeToTimestamp(this.messageList[i - 1].sendTime) <
          600000
        ) {
          //    console.log(i);
          this.messageList[i].sendTime = "";
        }
      }

      // let t  = JSON.stringify({q:this.text}
      // return
      // console.log(this.$route.query.id,this.$route.query,'已输入');
      //    this.topicId  =this.$route.query.id

      let obj = {
        userId: localStorage.id,
        topicId: this.$route.query.id || this.topicId,
        question: this.text,
      };
      // console.log(this.messageList[this.messageList.length-1]);
      // this.$api.chat.getAsk(obj)
      this.$api.chat.getAsk(obj)
        .then((res) => {
          console.log("op", res);
          if (res.code === 200) {
            this.messageList.pop(0, -1);
            this.messageList.push({
              sendByName: "Open",
              sendTime: res.data.time,
              is: false,
              content: res.data.msg,
              // content: "",
            });
            this.tokens = res.data.tokens;
            this.isTrue = false;
            this.$nextTick(() => {
              this.scrollToBottom();
            });

            let arr = res.data.msg;
            this.chatIndex = 0;
            this.cal = "";
            // this.demoStr(arr);

            for (let i = this.messageList.length - 1; i > 0; i--) {
              if (
                this.timeToTimestamp(this.messageList[i].sendTime) -
                  this.timeToTimestamp(this.messageList[i - 1].sendTime) <
                600000
              ) {
                this.messageList[i].sendTime = "";
              }
            }
          }
        })
        .catch((res) => {
          console.log(res, "no");
        });

      this.text = "";
    },
    scrollToBottom() {
      const scrollElem = this.$refs.scrollDiv;
      scrollElem.scrollTo({
        top: scrollElem.scrollHeight,
        behavior: "smooth",
      });
    },
    demoStr(arr) {
      if (this.chatIndex < arr.length) {
        this.cal = arr.substring(0, this.chatIndex++) + "_";
        this.messageList[this.messageList.length - 1].content = this.cal;
        this.timer = setTimeout(() => {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          this.demoStr(arr);
        }, 100);
      } else {
        this.messageList[this.messageList.length - 1].content = arr;

        this.isTrue = false;
      }
    },
    enIs(text) {
      this.messageList.push({
        sendByName: "1",
        sendTime: parseTime(Date.now()),
        content: text,
      });
      this.messageList.push({
        sendByName: "Open",
        sendTime: parseTime(Date.now()),
        is: true,
      });

      for (let i = this.messageList.length - 1; i > 0; i--) {
        if (
          this.timeToTimestamp(this.messageList[i].sendTime) -
            this.timeToTimestamp(this.messageList[i - 1].sendTime) <
          600000
        ) {
          //    console.log(i);
          this.messageList[i].sendTime = "";
        }
      }
      this.topicId = this.$route.query.id;

      let obj = {
        userId: localStorage.id,
        topicId: this.$route.query.id || this.topicId,
        question: text,
      };

      this.isTrue = true;

      this.$api.chat
        .getAsk(obj)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.messageList.pop(0, -1);
            this.messageList.push({
              sendByName: "Open",
              sendTime: res.data.time,
              is: false,
              content: "",
            });

            let arr = res.data.msg;
            this.chatIndex = 0;
            this.cal = "";
            this.demoStr(arr);

            for (let i = this.messageList.length - 1; i > 0; i--) {
              if (
                this.timeToTimestamp(this.messageList[i].sendTime) -
                  this.timeToTimestamp(this.messageList[i - 1].sendTime) <
                600000
              ) {
                this.messageList[i].sendTime = "";
              }
            }
          }
        })
        .catch((res) => {
          console.log(res, "no");
        });

      this.$nextTick(() => {
        this.$router.push({ query: {} });
      });

      // let newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 先拷贝一个一模一样的对象
      // delete newQuery.page //再删除page
      // this.$router.replace({ query: newQuery }) //再把新的替换了
    },
    //时间转换时间戳
    timeToTimestamp(time) {
      let timestamp = Date.parse(new Date(time).toString());
      //timestamp = timestamp / 1000; //时间戳为13位需除1000，时间戳为13位的话不需除1000
      //   console.log(time + "的时间戳为：" + timestamp);
      return timestamp;
      //2021-11-18 22:14:24的时间戳为：1637244864707
    },

    enBottom() {
      setTimeout(() => {
        this.scrollToBottom();
      }, 300);

      this.removeNO();

      // if (this.deviceEnvir() == "IOS") {
      //   // this.$toast('回到顶部')

      //   scrollElem.style.padding = ".16rem .16rem 2.6rem";
      //   scrollElem.scrollTo({
      //     top: scrollElem.scrollHeight,
      //     behavior: "smooth",
      //   });
      //   // document.body.style.overflow = 'hidden'
      // } else {
      //   scrollElem.style.padding = ".16rem .16rem 8rem";
      //   scrollElem.scrollTo({
      //     top: scrollElem.scrollHeight,
      //     behavior: "smooth",
      //   });
      // }
      // this.$nextTick(()=>{
      //     setTimeout(()=>{
      //       this.$toast( document.body.scrollTop+'获取')
      //     //   this.$refs.searchBar.style.height = document.documentElement.clientHeight + 'px'
      //   },500)
      // })
    },
    enBottomNo() {
      // let scrollElem = this.$refs.scrollDiv;
      // //  this.$refs.searchBar.style.height = 100 + 'vh'
      // // document.body.style.overflow = 'auto'
      // if (this.deviceEnvir() == "IOS") {
      //   scrollElem.style.padding = ".16rem .16rem 8rem";
      // } else {
      //   scrollElem.style.padding = ".16rem .16rem 4rem";
      // }
      // scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: "smooth" });
    },
    deviceEnvir() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      let isWeiChat = u.match(/MicroMessenger/i) == "micromessenger"; // 判断是否是微信环境
      if (isAndroid === true) {
        // 安卓
        return "ANDROID";
      } else if (isIOS === true) {
        // IOS
        return "IOS";
      } else if (isWeiChat === true) {
        //
        return "WEICHAT";
      } else {
        return "PC";
      }
    },
    //保存名字
    saveName(e) {
      // if(e.keyCode == 13){
      if (!this.newName || this.newName.length >= 12) {
        this.$toast("请输入名字并在十二个字符以内!");
        return;
      }
      this.nameChangeShow = false;
      this.btnText = false;

      this.$api.chat
        .changeChatName({
          id: this.id,
          title: this.newName,
        })
        .then((res) => {
          if (res.code === 200) {
            this.title = this.newName;
          }
        });

      // }
    },
  },
};
</script>
<style lang='less' scoped>
::v-deep.parser {
  .markdown-preview {
    max-width: 5rem;
    background: transparent;
    padding: 0 !important;
    .copy-code {
      opacity: 1 !important;
    }
  }
}
::v-deep.customer-msg {
  .markdown-preview {
    p {
      color: white;
    }
  }
}

.van-pull-refresh {
  // min-height: calc(100% - 0.4rem);
  user-select: initial;
  margin-bottom: 0.4rem;
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  position: relative;
  overflow: auto;

  background: url("../../assets/images/color.png") no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  background-color: #f5f7fb;
}
.input-name {
  background-color: transparent;
  text-align: center;
}

.chat-content {
  // padding: 0.16rem 0.16rem 5rem /* 8/50 */;
  padding: 0.16rem 0.16rem 0 /* 8/50 */;
  max-height: calc(100vh - 2.86rem);
  min-height: calc(100vh - 4.86rem);

  overflow: auto;

  .chat-list {
    width: 100%;
    box-sizing: border-box;

    ul {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      margin: 0.4rem /* 16/50 */ 0 0;

      .msg-info-time {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: 0.16rem /* 8/50 */ 0 0;
        margin-bottom: 0.4rem;

        font-size: 0.22rem /* 11/50 */;
        color: #a6a6a6;
      }

      li {
        font-size: 0.24rem /* 12/50 */;
        box-sizing: border-box;

        &.header-img {
          width: 0.72rem;
          height: 0.72rem;
          border-radius: 50% /* 3/50 */;
          overflow: hidden;
          background-color: #fff;
          text-align: center;

          & > span {
            font-size: 0.56rem /* 28/50 */;
          }

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        &.msg-info {
          width: 80%;

          .msg-info-con {
            // user-select: initial;
            background: #e8eaf1;
            // border-radius: 0.12rem 0.3rem 0.1rem 0.04rem;
            border-radius: 0.2rem;
            padding: 0.16rem 0.33rem 0.14rem 0.3rem /* 4.8/50 */;
            position: relative;
            display: inline-block;

            font-size: 0.3rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;

            line-height: 1.5 /* 19/50 */;
            letter-spacing: 0.02rem;
            word-spacing: 0.01rem;
            .copy-text {
              color: black;
              position: absolute;
              background: white;
              padding: 0.02rem 0.04rem;
              border-radius: 0.04rem;
              font-size: 0.26rem;
              top: 0;
              right: -0.8rem;
            }

            // .msg-tip {
            //     display: inline-block;
            //     width: 0;
            //     height: 0;
            //     border: 4px solid transparent;
            //     position: absolute;
            // }

            &.customer-bgc {
              // line-height: .36rem /* 19/50 */;
              padding: 0.16rem 0.3rem 0.14rem 0.32rem /* 4.8/50 */;
              // border-radius: 0.3rem 0.1rem 0.04rem 0.12rem;
              border-radius: 0.2rem;
              font-size: 0.3rem;

              line-height: 1.5 /* 19/50 */;
              letter-spacing: 0.02rem;
              word-spacing: 0.01rem;
              font-weight: 500;

              font-family: PingFang SC;
              color: #ffffff;
              background-color: #3b5bff;
              .copy-text {
                left: -0.8rem;
                right: inherit;
              }
            }
          }
        }

        &.server-list {
          em {
            display: block;
            font-weight: bold;
          }

          span {
            text-indent: 0.32rem /* 16/50 */;
            text-decoration: underline;
            display: block;
            color: rgb(101, 173, 201);
          }
        }
      }

      &.customer-server-msg {
        .msg-info {
          margin: 0 0.72rem /* 36/50 */ 0 0.2rem /* 12/50 */;

          .msg-info-con {
            word-wrap: break-word;
            word-break: break-all;

            .msg-tip {
              border-right: 9px solid #fff;
              top: 5px;
              left: -12px;
            }
          }
        }
      }

      &.customer-msg {
        justify-content: flex-end;

        .msg-info {
          margin: 0 0.2rem /* 12/50 */ 0 0.72rem /* 38/50 */;
          text-align: right;

          .msg-info-con {
            text-align: left;
            word-wrap: break-word;
            word-break: break-all;

            .msg-tip {
              border-left: 9px solid #ffbebf;
              top: 5px;
              right: -12px;
            }
          }
        }
      }
    }
  }
}

.header {
  // position: fixed;
  position: stickys;
  top: 0px;
  top: 0;
  left: 0;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;

  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.3rem;
  width: 100%;
  height: 1rem;
  font-size: 0.36rem;
  box-sizing: border-box;

  .back-icon {
    width: 0.2rem;
    height: 0.38rem;
  }

  .header-text {
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }
  .remove-icon {
    position: relative;
    .remove-text {
      position: absolute;
      top: 0.6rem;
      right: 0.04rem;
      width: 2rem;
      height: 0.58rem;
      background: #ffffff;
      opacity: 0.9;
      border-radius: 0.1rem;
      font-size: 0.28rem;
      text-align: center;
      line-height: 0.6rem;
      .remove-text-top {
        position: absolute;
        top: -0.1rem;
        right: 0.2rem;
        width: 0.16rem;
        height: 0.16rem;
        background-color: #fff;
        transform: rotate(45deg);
      }
    }
  }
}

.bottom {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 1.86rem;
  background-color: #ffffff;
  padding: 0.3rem;
  box-sizing: border-box;
  box-shadow: 0 0 0.2rem 0 rgba(107, 121, 148, 0.1);
  border-radius: 0.4rem 0.4rem 0 0;
  .helper {
    display: flex;
    .helper-item {
      display: flex;
      align-items: center;
      img {
        width: 0.22rem;
        height: 0.22rem;
      }
      span {
        margin-left: 0.09rem;
        font-size: 0.28rem;
        font-family: SF Pro Display;
        font-weight: 400;
        color: #7e7e7e;
      }
    }
  }
  .send-area {
    display: flex;
    align-items: center;
  }
  .record {
    width: 0.36rem;
    height: 0.36rem;
    margin: 0.32rem 0.32rem 0.3rem 0.3rem;
  }
  .text {
    // margin: 0.14rem 0;
    padding: 0.22rem 0.3rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    width: 6.22rem;
    // height: 0.7rem;
    background: #eef0f3;
    border-radius: 0.35rem;
    box-sizing: border-box;
  }
  .bottom-send {
    user-select: none;
    margin: 0 0 0 0.1rem;
    width: 1.6rem;
    height: 0.7rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    background: #3b5bff;
    border-radius: 0.35rem;
  }
}

//聊天列表
.chat-lists {
  // display: flex;
  // flex: 1;
  margin-top: 0.6rem;
  // overflow-y:scroll;
  // overflow-x:hidden;
  // // height:  80vh;
  // padding-bottom:5rem;
}

.customer-sendTime {
  padding-top: 1rem;
  // height:  1.8rem;
  // display: flex;
  // align-items: flex-end !important;
}
</style>